<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="4">
            <b-row>
              <b-col>
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.rates.label') }}
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <d-text-field
                  v-model="subscriptionPlanPrice.label"
                  :rules="isDisabled ? undefined : validation.label"
                  :disabled="isDisabled"
                  place-holder="components.form.subscriptions.new.rates.placeholder"
                  class-name="background-light-blue-inputs"
                  size="sm"
                  error-text="validation.text-input-three-characters"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-row>
              <b-col cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.price') }}
                </label>
              </b-col>
              <b-col cols="12">
                <b-input-group>
                  <b-form-input
                    v-model="subscriptionPlanPrice.price"
                    :disabled="isDisabled"
                    :placeholder="$t('general.actions.numbers.zero')"
                    type="number"
                    min="0"
                    size="sm"
                    value="0"
                    autocomplete="off"
                    class="background-light-blue-inputs"
                  />
                  <b-input-group-append is-text>
                    <div> {{ $currency }}</div>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.administration-fees') }}
                </label>
              </b-col>
              <b-col cols="12">
                <b-input-group>
                  <b-form-input
                    v-model="subscriptionPlanPrice.fees"
                    :disabled="isDisabled"
                    :placeholder="$t('general.actions.numbers.zero')"
                    type="number"
                    min="0"
                    size="sm"
                    autocomplete="off"
                    class="background-light-blue-inputs"
                  >
                  </b-form-input>
                  <b-input-group-append is-text>
                    <div>{{ $currency }}</div>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-if="index === 0"
            class="description-gray pr-0"
            cols="3"
          >
            {{ $t('components.form.subscriptions.new.descriptions.description-rates') }}
          </b-col>
        </b-row>
        <b-row
          v-if="index !== 0 && !isDisabled"
          class="pt-0 pb-0 mt-1 mb-0"
        >
          <b-col cols="9"/>
          <b-col
            align="right"
            cols="3"
          >
            <remove-rate
              text-link="components.form.subscriptions.new.remove-new-rate"
              @on:remove-link-click="$emit('on:remove-rate-click', subscriptionPlanPrice)"
            />
          </b-col>
        </b-row>
        <b-row
          :class="index === 0 ? 'mt-3' : 'mt-0'">
          <b-col cols="4">
            <b-row>
              <b-col>
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.nb-deadline') }}
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <d-text-field
                  v-model="subscriptionPlanPrice.paymentCount"
                  :disabled="isDisabled"
                  :rules="isDisabled ? undefined : validation.paymentCount"
                  size="sm"
                  type="number"
                  error-text="validation.required"
                  class-name="background-light-blue-inputs"
                  place-holder="general.actions.undefined"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="5">
            <b-row>
              <b-col cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.duration-each-deadline') }}
                </label>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="5">
                    <d-text-field
                      v-model="subscriptionPlanPrice.dueDateGap"
                      :disabled="isDisabled"
                      :rules="isDisabled ? undefined : validation.dueDateGap"
                      size="sm"
                      type="number"
                      class-name="background-light-blue-inputs"
                      place-holder="general.actions.undefined"
                      error-text="validation.sm-required"
                    />
                  </b-col>
                  <b-col>
                    <select
                      v-model="subscriptionPlanPrice.dueDateGapType"
                      :disabled="isDisabled"
                      size="sm"
                      class="form-control background-light-blue-inputs"
                      @change="dueDateGapTypeRequired = true"
                    >
                      <option :value="null" disabled>{{ $t('general.actions.choose') }}</option>
                      <option value="day">{{ $t('general.actions.day') }}</option>
                      <option value="week">{{ $t('general.actions.week.label') }}</option>
                      <option value="month">{{ $t('general.actions.months') }}</option>
                      <option value="year">{{ $t('general.actions.year') }}</option>
                    </select>
                    <b-form-invalid-feedback
                      :state="dueDateGapTypeRequired"
                    >
                      {{ $t('validation.required') }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-if="index === 0"
            class="description-gray pr-0"
            cols="3"
          >
            {{ $t('components.form.subscriptions.new.descriptions.description-rates') }}
          </b-col>
        </b-row>
        <b-row
          class="mt-3"
        >
          <b-col cols="12">
            <div class="d-flex">
              <vs-checkbox
                v-model="subscriptionPlanPrice.onlineSubscribable"
                :disabled="isDisabled"
                color="primary"
                value="false"
              />
              <p class="simple-label-avenir">
                {{ $t('components.form.subscriptions.new.subscribable') }}
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="subscriptionPlanPrice.dueDateGapType !== null"
          class="mt-3"
        >
          <b-col
            class="black-col-title"
            cols="12"
          >
            {{ $t('components.form.subscriptions.new.customer-will-be-billed') }}
            <b>
              {{ `${subscriptionPlanPrice.price}${$currency}` }}
            </b>
            {{ $t('components.form.subscriptions.new.every') }}
            <b>
              {{ subscriptionPlanPrice.dueDateGap }}
              {{ $t('views.subscriptions.new.rates.' + subscriptionPlanPrice.dueDateGapType) }}
            </b>
            {{ $t('components.form.subscriptions.new.during') }}
            <b>
              {{ parseInt(subscriptionPlanPrice.paymentCount) * parseInt(subscriptionPlanPrice.dueDateGap) }}
              {{ $t('views.subscriptions.new.rates.' + subscriptionPlanPrice.dueDateGapType) }}
            </b>
          </b-col>
        </b-row>
        <credits
          :subscription-plan-price="subscriptionPlanPrice"
          :is-disabled="isDisabled"
        />
        <div class="mt-3 border-bottom-grey"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>

export default {
  data: () => ({
    dueDateGapTypeRequired: true,
    validation: require('@validation/entities/doinsport/SubscriptionPlanPrice.json'),
  }),
  components: {
    RemoveRate: () => import('@custom/subscrtiptions/new/RemoveItemLink'),
    Credits :() => import('@views/subscriptions/subscription-list/subscription-create-update/subscription-variations/Credits'),
  },
  props: {
    validator: {
      type: Boolean,
      default: true
    },
    violations: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    subscriptionPlanPrice: {
      type: Object,
      default: () => {
      },
    }
  },
  watch: {
    validator: function () {
      this.dueDateGapTypeRequired = this.subscriptionPlanPrice.dueDateGapType !== null;
    },
  },
  created() {
    this.$vs.theme({
      primary: '#435179'
    });
  }
}
</script>

<style lang="scss" scoped>

/deep/ .input-group-text {
  line-height: 0;
}

.custom-padding {
  padding-left: 18px;
}
/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-family: 'Material Icons';
}
</style>
